import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
const FAQ = ({data}) => (
    <Layout>
        <Seo title="FAQ" />
        <div style={{maxWidth: "1000px", marginLeft: "auto", marginRight: "auto", padding: "2em"}} dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}></div>
    </Layout>
)

export const query = graphql`
  query FAQMDQuery{
    markdownRemark(fileAbsolutePath: {regex: "/\/src\/pages\/FrequentlyAskedQuestions.md/"}) {
        frontmatter{
            title
        }
        html
    }
}
`

export default FAQ